<template>
  <div>
    <el-card style="height: 89vh">
      <!-- basicInformation 基本信息 -->
      <h2 class="ml">{{ $t("account.basicInformation") }}:</h2>
      <div class="basicInformation">
        <div class="rolename">
          <p>{{ $t("account.userName") }}：{{ userName }}</p>
          <p class="mt1">{{ $t("account.mailbox") }}：{{ mailbox }}</p>
        </div>
        <div class="mailbox">
          <p>{{ $t("account.mobilePhone") }}：{{ mobilePhone }}</p>
          <p class="mt1">{{ $t("account.rolename") }}：{{ rolename }}</p>
        </div>
        <!-- <p class="mt">{{ $t("account.rolename") }}：management</p> -->
        <!-- <div class="mt">
          {{
            $t("account.jurisdiction")
          }}：Orders，commodity，shop，Subscriptions
        </div> -->
      </div>
      <el-divider></el-divider>

      <!-- Login record 登录记录 -->
      <div class="loginRecord">
        <h2>{{ $t("account.loginRecord") }}：</h2>
        <el-card class="mt title">
          <el-row>
            <el-col :span="3" align="center">{{ $t("account.time") }}</el-col>
            <el-col :span="4" align="center">{{ $t("account.state") }}</el-col>
            <el-col :span="5" align="center">{{
              $t("account.operationType")
            }}</el-col>
            <el-col :span="9" align="center">{{
              $t("account.operationContent")
            }}</el-col>
            <el-col :span="3" align="center">{{
              $t("account.IPAddress")
            }}</el-col>
          </el-row>
        </el-card>
        <el-card class="main">
          <el-col :span="3" align="center">December 12, 2022</el-col>
          <el-col :span="4" align="center">normal</el-col>
          <el-col :span="5" align="center">PC login</el-col>
          <el-col :span="9" align="center"
            >Login succeeded, verify 152 * * * * 124 information</el-col
          >
          <el-col :span="3" align="center">1255.25</el-col>
        </el-card>
        <el-card class="main">
          <el-col :span="3" align="center">December 12, 2022</el-col>
          <el-col :span="4" align="center">normal</el-col>
          <el-col :span="5" align="center">PC login</el-col>
          <el-col :span="9" align="center"
            >Login succeeded, verify 152 * * * * 124 information</el-col
          >
          <el-col :span="3" align="center">1255.25</el-col>
        </el-card>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userName: "",
      mailbox: "",
      mobilePhone: "",
      rolename: "",
    };
  },
  created() {
    const row = JSON.parse(decodeURIComponent(this.$route.query.row));
    this.row(row);
  },
  methods: {
    // 基本信息
    row(row) {
      console.log("row:", row);
      this.userName = row.userName;
      this.mailbox = row.email;
      this.mobilePhone = row.mobile;
      this.rolename = row.authority.authorityName;
    },
  },
};
</script>

<style scoped>
.basicInformation {
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
}
.rolename {
  margin-right: 30%;
  margin-top: 20px;
}
.mailbox {
  margin-top: 20px;
}
.loginRecord {
  margin-left: 20px;
}
.title {
  background: #ededed;
  border: 1px solid #dbdbdb;
}
.main {
  border: 1px solid #dbdbdb;
  padding-bottom: 20px;
  border-top: none;
}
</style>